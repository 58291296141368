import React from 'react';
import { Link } from "react-router-dom";

const Header = () => {   
    
  return (
    <nav className="navbar navbar-expand-lg navbar-dark ">
  <div className="container">
    <Link className="navbar-brand" to="/">
      <img style={{height: 53, width: 200}} id="main-logo" src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1657779551/cran-capitaldark3d.png" className="logo" alt="logo" />
    </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse" id="navbarNavDropdown">
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/">Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/about">About Us</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/affiliate">Affiliate</Link>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Account</a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="https://dashboard.crancapitallimited.net">Login</a></li>
            <li><a className="dropdown-item" href="https://dashboard.crancapitallimited.net">Registration</a></li>
          </ul>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/contact">Contact</Link>
        </li>
        <li className="nav-item">
          <a className="nav-link button-1 text-white" href="https://dashboard.crancapitallimited.net/">Account</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

  )
}
export default Header