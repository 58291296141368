import React from 'react'

const Gateway = () => {
    
    return (
<div className="getway" style={{marginTop: '-150px'}}>
  <div className="container ">
    <div className="row justify-content-center gy-4">
      <div className="col-lg-8">
        <div className="brands-title text-center">
          <h2 className="p-xl h2 mb-3">
            Accepted payment wallets and gateways 
          </h2>
        </div>
      </div>
    </div>
    <div className="brands-2-wrapper">
      <div className="row g-4 d-flex align-content-stretch flex-wrap row-cols-2 row-cols-sm-3 row-cols-md-5">
        <div className="col">
          <div className="brand-logo shadow-sm white-color p-2 text-center bg-light">
            <a href="#">
              <img className="avatar avatar-circle" height={70} src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1643287466/456-4561309_usdt-tron-hd-png-download.png" alt="brand-logo" />
            </a>
          </div>
        </div>
        <div className="col">
          <div className="brand-logo shadow-sm white-color p-2 text-center bg-light">
            <a href="#">
              <img className="avatar avatar-circle" height={70} src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1643287437/bnb-logo-e1619884060266.png" alt="brand-logo" />
            </a>
          </div>
        </div>
        <div className="col">
          <div className="brand-logo shadow-sm white-color p-2 text-center bg-light">
            <a href="#">
              <img className="avatar avatar-circle" height={70} src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1643287399/rbtp5a02kaj69iggyeev.jpg" alt="brand-logo" />
            </a>
          </div>
        </div>
        <div className="col">
          <div className="brand-logo shadow-sm white-color p-2 text-center bg-light">
            <a href="#">
              <img className="avatar avatar-circle" height={70} src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1643287295/af3plmlacrf1ehiqkx7e.png" alt="brand-logo" />
            </a>
          </div>
        </div>
        <div className="col">
          <div className="brand-logo shadow-sm white-color p-2 text-center bg-light">
            <a href="#">
              <img className="avatar avatar-circle" height={70} src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1643286848/binance-logo-1.png" alt="brand-logo" />
            </a>
          </div>
        </div>
        <div className="col">
          <div className="brand-logo shadow-sm white-color p-2 text-center bg-light">
            <a href="#">
              <img className="avatar avatar-circle" height={70} src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1643286877/coinbase-new4201.jpg" alt="brand-logo" />
            </a>
          </div>
        </div>
        <div className="col">
          <div className="brand-logo shadow-sm white-color p-2 text-center bg-light">
            <a href="#">
              <img className="avatar avatar-circle" height={70} src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1643286899/vertical_blue.png" alt="brand-logo" />
            </a>
          </div>
        </div>
        <div className="col">
          <div className="brand-logo shadow-sm white-color p-2 text-center bg-light">
            <a href="#">
              <img className="avatar avatar-circle" height={70} src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1643442780/images.png" alt="brand-logo" />
            </a>
          </div>
        </div>
        <div className="col">
          <div className="brand-logo shadow-sm white-color p-2 text-center bg-light">
            <a href="#">
              <img className="avatar avatar-circle" height={70} src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1643442646/0fd9fc18b5d0060f9cc6ed2310a9d4fc.jpg" alt="brand-logo" />
            </a>
          </div>
        </div>
        <div className="col">
          <div className="brand-logo shadow-sm white-color p-2 text-center bg-light">
            <a href="#">
              <img className="avatar avatar-circle" height={70} src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1643287041/coingecko-logo-vector.png" alt="brand-logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    )
}

export default Gateway;