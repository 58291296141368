import React, {useState, useEffect} from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import Home from './components/Home';
import Affiliate from './components/Affiliate';
import Contact from './components/Contact';
import Faq from './components/Faq';
import Terms from './components/Terms';
import About from './components/About';

function App() {

  return (
    <div> 
          <Routes>   
          <Route path='/' element={<Home/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/affiliate' element={<Affiliate/>} />
          <Route path='/contact' element={<Contact/>} />
          <Route path='/terms' element={<Terms/>} />
          <Route path='/faq' element={<Faq/>} />
          </Routes>      
    </div>
  );
}

export default App;
